import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c6fef45c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "login" }
const _hoisted_2 = { class: "login__body container app-block-content" }
const _hoisted_3 = { class: "login__header mx-auto text-2xl mb-14 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldText = _resolveComponent("FieldText")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("form", {
        class: "m-auto",
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.submit()), ["prevent"]))
      }, [
        _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.t('login-header')), 1),
        _createVNode(_component_FieldText, {
          label: _ctx.t('email-lbl'),
          modelValue: _ctx.form.email,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.email) = $event)),
          error: _ctx.form.emailErr,
          type: "email",
          name: "email",
          autocomplete: "username",
          placeholder: _ctx.t('email-ph'),
          isDisabled: _ctx.isSubmitting
        }, null, 8, ["label", "modelValue", "error", "placeholder", "isDisabled"]),
        _createVNode(_component_FieldText, {
          class: "mt-4",
          label: _ctx.t('password-lbl'),
          modelValue: _ctx.form.password,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.password) = $event)),
          error: _ctx.form.passwordErr,
          type: "password",
          name: "password",
          autocomplete: "current-password",
          placeholder: _ctx.t('password-ph'),
          isDisabled: _ctx.isSubmitting
        }, null, 8, ["label", "modelValue", "error", "placeholder", "isDisabled"]),
        _createVNode(_component_Button, {
          class: "login__submit-btn mt-14 rounded-3xl mx-auto block font-bold max-w-full",
          type: "submit",
          isDisabled: !_ctx.form.isValid || _ctx.isSubmitting
        }, {
          default: _withCtx(() => [
            (!_ctx.isSubmitting)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString(_ctx.t('submit-btn')), 1)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(_ctx.t('submitting-btn')), 1)
                ], 64))
          ]),
          _: 1
        }, 8, ["isDisabled"])
      ], 32)
    ])
  ]))
}