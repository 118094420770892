
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useForm, validators } from '@/composables/useForm'
import { callers, getApiErrorStatus, reportApiError } from '@/api'
import FieldText from '@/components/FieldText.vue'
import Button from '@/components/Button.vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useSubmitter } from '@/composables/useSubmitter'

export default defineComponent({
  components: { FieldText, Button },
  setup() {
    const t = useI18n().t
    const { dispatch } = useStore()
    const router = useRouter()

    const form = useForm({
      email: ['', validators.required, validators.email],
      password: ['', validators.required],
    })
    const [submit, isSubmitting] = useSubmitter({
      request: () =>
        callers.login({
          email: form.email.val(),
          password: form.password.val(),
        }),
      guard: () => form.checkValid(),
      onSuccess: ({ data: token }) => {
        dispatch('LOG_IN', { token })
        router.push({ name: 'Redirector' })
      },
      onError: (error) => {
        if (getApiErrorStatus(error) === 401) {
          form.password.err(t('credentials-err'))
        } else {
          reportApiError(error)
        }
      },
    })

    return {
      form: form.flatten(),
      isSubmitting,
      submit,
      t,
    }
  },
})
