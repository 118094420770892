export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en-US",
    "resource": {
      "login-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "email-lbl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "email-ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "password-lbl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "password-ph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "submit-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "submitting-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitting…"])},
      "credentials-err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email or password is invalid"])}
    }
  })
}
